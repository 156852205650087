import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token:string;
  private userDetails:any;
  private isAuthStatus = false;
  private authListener = new Subject<boolean>();
  timeInterval: any;

  api = environment.apiUrl;
  constructor(private router: Router, private http: HttpClient) { }

  getAuthToken() {
    return this.token;
  }

  getAuthStatus() {
    return this.isAuthStatus;
  }

  getAuthListener() {
    return this.authListener.asObservable();
  }

  getUserData() {
    return this.userDetails;
  }

  getUserDetails(userData) {
    sessionStorage.removeItem("tempToken");
    sessionStorage.removeItem("expiresAt");
    const token = userData.apiGardenToken;
    this.token = token;
    console.log("token::", this.token);
    if(token) {
      this.userDetails = userData;
      const expireToken = 1800;
      // this.setAuthTimer(expireToken);
      this.isAuthStatus = true;
      this.authListener.next(true);
      const now = new Date();
      const expireDate = new Date(now.getTime() + expireToken * 1000);
      this.saveAuth(userData, expireDate);
      this.router.navigate(["/"]);
      console.log("this.isAuthStatus::::",this.isAuthStatus)
    } else {
      this.authListener.next(false);
    }

  }

  // private setAuthTimer(duration) {
  //   this.timeInterval = setTimeout(()=>{
  //     this.logout();
  //   }, duration * 1000);
  // }

  logout() {
    this.token = null;
    this.isAuthStatus = false;
    this.authListener.next(false);
    // clearTimeout(this.timeInterval);
    this.clearAuth();
    this.getTempToken();
    this.router.navigate(["/"]);
  }


  private saveAuth(userData, expireDate:Date) {
    // console.log(userData);
    localStorage.setItem("token", userData.apiGardenToken);
    localStorage.setItem("email", userData.email);
    localStorage.setItem("fName", userData.fName);
    localStorage.setItem("orgPage", userData.orgPage);
    localStorage.setItem("truid", userData.truid);
    localStorage.setItem("expiresAt", expireDate.toISOString());
  }

  private clearAuth() {
    localStorage.removeItem("token");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("email");
    localStorage.removeItem("fName");
    localStorage.removeItem("orgPage");
    localStorage.removeItem("truid");
    localStorage.removeItem("tempToken")
  }

  getAuthData() {
    const token = localStorage.getItem("token");
    const duration = localStorage.getItem("expiresAt");
    const email = localStorage.getItem("email");
    const fName = localStorage.getItem("fName");
    const orgPage = localStorage.getItem("orgPage");
    const truid = localStorage.getItem("truid");

    if(!token || !duration) {
      return;
    }

    return {
      apiGardenToken: token,
      expirationDate: new Date(duration),
      email: email,
      fName: fName,
      truid: truid,
      orgPage: orgPage
    }
  }

  autoAuthData() {
    const authDetails = this.getAuthData();
    if(!authDetails){
      return;
    }
    const now = new Date();
    const expiresAt = authDetails.expirationDate.getTime() - now.getTime();
    if(expiresAt > 0) {
      this.token = authDetails.apiGardenToken;
      this.isAuthStatus = true;
      // this.setAuthTimer(expiresAt / 1000);
      this.authListener.next(true);
    }
  }


  getTempToken(){
    this.http.get(this.api + '/mdaas/ws/auth/getTempToken').subscribe((data)=>{
      // console.log("temptoken::;:",data);
      // console.log("temptoken jsonData::", JSON.stringify(data));
      let tempToken = environment.accesstokenPROD;
      // let expireDate = data['expiresAt'];
      let expireDate = new Date("2050-12-31");
      this.token = tempToken;
      let tempTokenExpireAt = expireDate.toISOString();
      sessionStorage.setItem("tempToken", tempToken);
      localStorage.setItem("tempToken", tempToken);
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      sessionStorage.setItem("expiresAt", tempTokenExpireAt);
    })
  }

  autoTempData(token, expireDate) {
    if(!token || !expireDate){
      return;
    }
    const now = new Date();
    const expireAt = new Date(expireDate).getTime() - now.getTime();
    if(expireAt < 0){
      sessionStorage.removeItem("tempToken");
      sessionStorage.removeItem("expiresAt");
      this.token = null;
      this.getTempToken();
    }else {
      this.token = token;
      // this.setTempTimer(expireAt/1000);
    }
  }

  // setTempTimer(duration) {
  //   setTimeout(()=>{
  //     sessionStorage.removeItem("tempToken");
  //     sessionStorage.removeItem("expiresAt");
  //     this.token = null;
  //     this.getTempToken();
  //   }, duration * 1000)
  // }
}
